import { useState, useEffect } from '@wordpress/element';
import { TextControl, Spinner, Button, Notice } from '@wordpress/components';
import { __ } from '@wordpress/i18n';
import metadata from './block.json';
import apiFetch from '@wordpress/api-fetch';
import {float} from "../../../../../wp/wp-includes/js/codemirror/csslint";

// const { registerCheckoutBlock } = wc.blocksCheckout;
const { registerCheckoutBlock } = window.wc.blocksCheckout;


const Block = ({ children, checkoutExtensionData }) => {
    const [shippingDates, setShippingDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [shippingMethods, setShippingMethods] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false); // Manage loading state
    const [shippingLoading, setShippingLoading] = useState(false); // Manage loading state
    const [cartData, setCartData] = useState(null);

    useEffect(() => {
        fetchShippingCosts();
    }, []);

    // Functie om winkelwagen- en klantgegevens op te vragen
    async function getCheckoutData() {
        try {
            const response = await apiFetch({
                path: '/wc/store/v1/cart',  // Store API-endpoint om cart-gegevens op te vragen
                method: 'GET'
            });

            return response;  // Bevat gegevens zoals items en klantgegevens
        } catch (error) {
            console.error('Error fetching checkout data:', error);
        }
    }

    // Voorbeeld functie om de verzendkosten te berekenen
    async function fetchShippingCosts() {
        setLoading(true);
        const checkoutData = await getCheckoutData();

        if (!checkoutData) {
            console.error('Checkout data niet beschikbaar');
            return;
        }
        console.info(checkoutData);

        // Controleer de items in de winkelwagen en haal de calculation ID op uit meta_data
        const items = checkoutData.items.map(item => {
            // Zoek de calculation ID in de meta_data van het item
            const calculationMeta = item.item_data.find(meta => meta.key === 'sync-item-id');
            const calculationId = calculationMeta ? calculationMeta.value : '';  // Gebruik de waarde als deze beschikbaar is

            return {
                calculation: calculationId,  // Dynamische calculation ID vanuit meta_data
                product_id: item.id,
                variation_id: item.variation_id || 0,
                quantity: item.quantity,
                sync_silo_product: ''  // Dit moet ook dynamisch worden ingevuld als beschikbaar
            };
        });

        // Bouw het volledige JSON-object op
        const dataToSend = {
            timestamp: new Date().toISOString(),  // Dynamisch tijdstip
            siteurl: window.location.origin,  // Dynamische site-URL
            include_vat: checkoutData.prices_include_tax,
            items: items,
            address: {
                first_name: checkoutData.billing_address.first_name,
                last_name: checkoutData.billing_address.last_name,
                company: checkoutData.billing_address.company,
                country: checkoutData.shipping_address.country || checkoutData.billing_address.country,
                address_street: checkoutData.shipping_address.address_1 || checkoutData.billing_address.address_1,
                address_number: checkoutData.shipping_address.address_2 || checkoutData.billing_address.address_2,
                city: checkoutData.shipping_address.city || checkoutData.billing_address.city,
                state: checkoutData.shipping_address.state || checkoutData.billing_address.state,
                postal_code: checkoutData.shipping_address.postcode || checkoutData.billing_address.postcode,
                phone: checkoutData.billing_address.phone,
                email: checkoutData.billing_address.email,
            },
        };
        setCartData(dataToSend);
        // Verstuur gegevens naar backend via je REST API
        try {
            const shippingResponse = await apiFetch({
                path: '/syncsilo/v1/shipping/dates',
                method: 'POST',
                data: dataToSend,
            });
            setShippingDates(shippingResponse);
            setIsLoading(false);  // Stop de loading state
            console.log('Shipping costs response:', shippingResponse);
        } catch (error) {
            setIsLoading(false);  // Stop de loading state
            console.error('Error fetching shipping costs:', error);
        }
        setLoading(false);
    }
    const handleMethodSelect = (method) => {
        setSelectedMethod(method.uuid);
    }
    const handleDateSelect = (date) => {
        setShippingLoading(true);
        setShippingMethods([]);
        setSelectedMethod(null);
        setSelectedDate(date.id);
        // Versturen van de geselecteerde datum naar de backend om deze op te slaan in de WooCommerce sessie
        apiFetch({
            path: '/syncsilo/v1/shipping/costs',
            method: 'POST',
            data: {
                shipping_date: date.id,
                cart: cartData
            }
        }).then((response) => {
            setShippingMethods(response);
            console.info(response);
            setShippingLoading(false);
        }).catch((error) => {
            console.info(error);
            setShippingLoading(false);
        });
    };

    const numberFormat = (value) =>
        new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);

    if (isLoading) {
        return <p><Spinner/> {__('Mogelijke leverdatums worden geladen, moment geduld aub...', 'syncsilo')}</p>;
    }

    if (shippingDates.length === 0) {
        return <p style={{padding: '16px'}}>{__('Geen verzenddatums beschikbaar.', 'syncsilo')}</p>;
    }

    return (
        <div className="syncsilo-shipping-dates">
            <h4>{__('Selecteer een verzenddatum', 'syncsilo')}</h4>
            <div className={'wc-block-components-radio-control wc-block-components-radio-control--highlight-checked'}>
                {shippingDates.dates && shippingDates.dates.length > 0 ? (
                    shippingDates.dates.map((date, index) => (
                        <div key={date.id} className={'wc-block-components-radio-control-accordion-option'}>
                            <label className={'wc-block-components-radio-control__option'}>
                                <input
                                    type="radio"
                                    name={'shippingDate' + index}
                                    className={'wc-block-components-radio-control__input'}
                                    value={date.id}
                                    onChange={() => handleDateSelect(date)}
                                    checked={selectedDate === date.id}
                                />
                                <div className="wc-block-components-radio-control__option-layout">
                                    <div className="wc-block-components-radio-control__label-group">
                                        <span className="wc-block-components-radio-control__label">
                                            <div className="PPMFWC_method">{date.label}
                                                <span style={{
                                                    float: 'right'
                                                }}>
                                                    <strong>{(date.cost === 'No surchange cost') ? '' : `+ ${numberFormat(date.cost)}`}</strong>
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </label>
                        </div>
                    ))
                ) : (
                    <p>{__('Geen verzenddatums beschikbaar.', 'syncsilo')}</p>
                )}
            </div>
            <h4 style={{marginTop: '16px'}}>{__('Selecteer een vervoerder', 'syncsilo')}</h4>
            {/*{shippingLoading && <Spinner/>}*/}
            <div className={'wc-block-components-radio-control wc-block-components-radio-control--highlight-checked'}>
                {shippingMethods && shippingMethods.length > 0 ? (
                    shippingMethods.map((method, index) => (
                        <div key={method.uuid} className={'wc-block-components-radio-control-accordion-option'}>
                            <label className={'wc-block-components-radio-control__option'}>
                                <input
                                    type="radio"
                                    name={'shippingMethod_' + index}
                                    className={'wc-block-components-radio-control__input'}
                                    value={method.uuid}
                                    onChange={() => handleMethodSelect(method)}
                                    checked={selectedMethod === method.uuid}
                                />
                                <div className="wc-block-components-radio-control__option-layout">
                                    <div className="wc-block-components-radio-control__label-group">
                                            <span className="wc-block-components-radio-control__label">
                                                <div className="PPMFWC_method">
                                                    {method.name}
                                                    <img src={method.icon} style={{float: 'right', height: '24px'}}/>
                                                    <span style={{float: 'right', marginRight: '16px'}}>{numberFormat(method.total_price)}</span>
                                                </div>
                                            </span>
                                    </div>
                                </div>
                            </label>
                        </div>
                    ))
                ) : (
                    (!selectedDate) ? (
                        <p style={{padding: '16px'}}>{__('Geen verzenddatum geselecteerd.', 'syncsilo')}</p>
                    ) : (
                        <p style={{padding: '16px'}}><Spinner/> {__('Vervoerders worden geladen...', 'syncsilo')}</p>
                    )
                )}
            </div>
        </div>
    );
};

const options = {
    metadata,
    component: Block
};
registerCheckoutBlock(options);
